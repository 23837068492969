import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import * as styles from './MapPopupContent.module.css';

export const MapPopupContent = (props) => {
  const { name, address, phone, website, email } = props;
  return (
    <div className={clsx(styles.MapPopupContent, props.className)}>
      <h5>
        <span>{name}</span>
      </h5>
      <div>
        {address.streetAddr}, <br />
        {address.zip} {address.city}
      </div>
      <hr />
      {phone && <span>Tel. {phone}</span>}
      <div className={styles.popupLinks}>
        {email && (
          <a href={`mailto: ${email}`}>
            <i className="icon-mail" />
            &nbsp;E-Mail
          </a>
        )}
        {website && (
          <a href={`${website}`} target="_blank">
            <i className="icon-website" />
            &nbsp;Besuchen
          </a>
        )}
      </div>
    </div>
  );
};

MapPopupContent.propTypes = {
  className: PropTypes.string,
};
MapPopupContent.defaultProps = {
  className: '',
};
