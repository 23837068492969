import React, { useState } from 'react';
import { string, func } from 'prop-types';

import { Suggestions } from './Suggestions';
import GMapsAPI from '../../utils/GmapsAPI';

import * as styles from './LocationField.module.css';

export const LocationField = (props) => {
  const { address, onChange, disabled } = props;
  const [suggestions, setSuggestions] = useState([]);

  const updateSuggestions = (addressSearchString) => {
    onChange(addressSearchString);

    GMapsAPI.getAddressSuggestions(addressSearchString, (suggestions) => {
      setSuggestions(suggestions);
    });
  };

  const selectSuggestion = (location) => {
    if (!location) {
      setSuggestions([]);
    } else {
      onChange(location);
      setSuggestions([]);
    }
  };

  return (
    <>
      <input
        className={styles.locationField}
        type="text"
        disabled={disabled}
        autoComplete="off"
        placeholder={typeof navigator !== 'undefined' && navigator.geolocation ? 'automatisch ermitteln' : ''}
        value={address}
        onChange={(e) => {
          updateSuggestions(e.target.value);
        }}
      />
      {suggestions && <Suggestions suggestions={suggestions} onSelect={selectSuggestion} />}
    </>
  );
};

LocationField.propTypes = {
  address: string,
  onChange: func.isRequired,
};

LocationField.defaultProps = {
  address: '',
};
