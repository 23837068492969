/* global google */
import loadGoogleMapsApi from 'load-google-maps-api';

class GMapsAPI {
  devKey = 'AIzaSyCTyHvNrx25TJauVOK5IPW2KH9AKCzssdo';
  language = 'de';
  apiLoadedSuccessfully = false;

  googleMapsOptions = {
    key: 'AIzaSyCTyHvNrx25TJauVOK5IPW2KH9AKCzssdo',
    language: 'de',
    region: 'AT',
    libraries: ['places'],
  };

  autoCompleteOptions = {
    types: ['(regions)'],
    bounds: {
      east: 8.8502013683,
      south: 46.1177667004,
      west: 17.8199310303,
      north: 49.1546072583,
    },
    componentRestrictions: { country: 'AT' },
    strictBounds: true,
  };

  constructor() {
    if (typeof window !== 'undefined')
      loadGoogleMapsApi(this.googleMapsOptions)
        .then(this.apiLoadComplete)
        .catch(function (error) {
          console.error(error);
        });
  }

  apiLoadComplete = (googleMaps) => {
    this.autoCompleteOptions.bounds = new google.maps.LatLngBounds();
    this.apiLoadedSuccessfully = true;
    this.googleMaps = googleMaps;
  };

  getCoords(
    str,
    callback = (json) => {
      console.log(json);
    },
  ) {
    const service = new google.maps.Geocoder();
    service.geocode(
      {
        address: str,
        bounds: this.autoCompleteOptions.bounds,
      },

      (result) => {
        const localitiesInAustria = result.filter((locality) => {
          const { address_components } = locality;
          return address_components.find((component) => component.short_name === 'AT');
        });

        const coordinates =
          localitiesInAustria.length > 0
            ? localitiesInAustria[0].geometry.location.toJSON()
            : result[0].geometry.location.toJSON();

        callback({
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        });
      },
    );
  }
  initMapTester() {
    new this.googleMaps.Map(document.querySelector('#mapContainer'), {
      center: {
        lat: 40.7484405,
        lng: -73.9944191,
      },
      zoom: 12,
    });
  }

  getAddress = ({ latitude, longitude }, callback) => {
    const service = new google.maps.Geocoder();
    service.geocode(
      {
        location: {
          lat: latitude,
          lng: longitude,
        },
        region: 'AT',
        bounds: this.autoCompleteOptions.bounds,
      },
      (result) => {
        const locality = result.find((entry) => {
          return entry.types.indexOf('locality') !== -1;
        });

        if (locality) {
          return callback(locality.formatted_address);
        }

        callback(result[0].formatted_address);
      },
    );
  };

  getCityFromCode = (postalCode, callback) => {
    const service = new google.maps.Geocoder();
    service.geocode(
      {
        address: postalCode,
        region: 'AT',
        bounds: this.autoCompleteOptions.bounds,
      },
      (result) => {},
    );
  };

  getAddressSuggestions(str, callback) {
    if (!str) {
      callback(null);
      return null;
    }
    if (!google && google.maps) {
      console.info('google maps client side API not loaded');
      callback([]);
    }

    const service = new google.maps.places.AutocompleteService();
    const request = {
      ...this.autoCompleteOptions,
      input: str,
    };

    service.getPlacePredictions(request, (result) => {
      if (result) {
        const array = [];
        result.forEach((entry) => {
          array.push(entry.description);
        });
        callback(array);
      }
    });
  }
}
export default new GMapsAPI(); //singleton
