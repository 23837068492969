import React from 'react';

export const BrandSelect = (props) => {
  const { onChange, brands, ...rest } = props;
  return (
    <select {...rest} onChange={(e) => onChange(e.currentTarget.value)}>
      <option value="">Alle Marken</option>
      {brands.map((brand) => (
        <option value={brand} key={brand}>
          {brand}
        </option>
      ))}
    </select>
  );
};
