import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { list, selected } from './Suggestions.module.css';

export const Suggestions = (props) => {
  const { onSelect, suggestions } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    document.addEventListener('keydown', handleKeysDown);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeysDown);
      document.removeEventListener('click', handleClick);
    };
  });

  const handleSelect = (str) => {
    if (str && typeof str === 'string') {
      onSelect(str);
    } else {
      onSelect(null);
    }
  };

  const handleClick = (str) => {
    handleSelect(str);
  };

  const handleKeysDown = (e) => {
    if (e.key === 'Enter') {
      handleSelect(suggestions[selectedIndex]);
    }
    if (e.key === 'Escape') {
      handleSelect(false);
    }
    if (e.keyCode === 38) {
      // up
      e.preventDefault();
      if (selectedIndex == null || selectedIndex === 0) {
        setSelectedIndex(() => suggestions.length - 1);
      } else {
        setSelectedIndex((selectedIndex) => selectedIndex - 1);
      }
    } else if (e.keyCode === 40) {
      // down
      e.preventDefault();
      if (selectedIndex == null) {
        setSelectedIndex(() => 0);
      } else if (selectedIndex === suggestions.length - 1) {
        setSelectedIndex(() => 0);
      } else {
        setSelectedIndex((selectedIndex) => selectedIndex + 1);
      }
    }
  };

  if (!suggestions || suggestions.length === 0) {
    return null;
  }
  return (
    <ul className={list}>
      {suggestions.map((suggestion, i) => {
        return (
          <li
            key={suggestion}
            className={clsx(selectedIndex === i && selected)}
            onClick={() => {
              handleClick(suggestion);
            }}
          >
            {suggestion}
          </li>
        );
      })}
    </ul>
  );
};

Suggestions.propTypes = {
  suggestions: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};
Suggestions.defaultProps = {
  suggestions: null,
};
