import geolib from 'geolib';

export const sortByDistance = (a, b) => {
  const distA = a.distance;
  const distB = b.distance;
  if (distA > distB) {
    return 1;
  }
  if (distA < distB) {
    return -1;
  }
  return 0;
};

export const sortAlphabetically = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const sortPremiumToTop = (a, b) => {
  if (!a.isPremium && b.isPremium) return 1;
  if (a.isPremium && !b.isPremium) return -1;
  return 0;
};

export const getDistance = (coords1, coords2) => {
  return geolib.getDistance(coords1, coords2);
};

export const getBounds = () => {
  // return
  const bounds = geolib.getBounds([
    { latitude: 52.516272, longitude: 13.377722 },
    { latitude: 51.515, longitude: 7.453619 },
    { latitude: 51.503333, longitude: -0.119722 },
  ]);
};

export const getCenter = (
  positions = [
    { latitude: 52.516272, longitude: 13.377722 },
    { latitude: 51.515, longitude: 7.453619 },
  ],
) => {
  if (positions[0].lat) {
    positions = convertFromLatLng(positions);
  }
  return geolib.getCenter(positions);
};

function convertFromLatLng(latLng = []) {
  return latLng.map((obj) => {
    return { latitude: obj.lat, longitude: obj.lng };
  });
}
