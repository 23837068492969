import React from 'react';
import PropTypes from 'prop-types';

import {
  dealers__list,
  dealers__listitem,
  dealers__listitemcontent,
  buttons,
  addressLine,
} from './ListOfContacts.module.css';

const Contact = ({ data }) => {
  const { name, address, distance, phone, website, email } = data;
  return (
    <div className={dealers__listitem}>
      <div className={dealers__listitemcontent}>
        <h5>
          <span style={{ display: 'block' }}>{name}</span>
          {distance ? <span style={{ float: 'right' }}>{distance} km</span> : null}
        </h5>
        <div className={addressLine}>
          {address.streetAddr}, {address.zip} {address.city}
        </div>
        {phone && <span> Tel. {phone} </span>}
        <div className={buttons}>
          {email && (
            <a href={`mailto: ${email}`}>
              <button className="has-background">E-Mail senden</button>
            </a>
          )}
          {website && (
            <a href={`${website}`} target="_blank">
              <button className="has-background">Webseite besuchen</button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export const ListOfContacts = ({ listOfContacts }) => {
  return (
    <section className={dealers__list}>
      {listOfContacts && listOfContacts.map((contact) => <Contact key={contact.id} data={contact} />)}
    </section>
  );
};

ListOfContacts.propTypes = {
  listOfContacts: PropTypes.array,
};

ListOfContacts.defaultProps = {
  listOfContacts: null,
};
