import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import GMapsAPI from '../utils/GmapsAPI';

import { MapPopupContent } from './MapPopupContent';
import * as styles from './LazyGMap2.module.css';
import ReactDOM from 'react-dom';

export const LazyGMap2 = (props) => {
  const { list, className, center, zoom, furthestZoom, closestZoom } = props;
  const map = useRef();
  const mapContainer = useRef();

  const statelessMarkers = [];
  const infoWindows = [];
  let mapHasCloseListener = false;

  const clearMarkers = () => {
    statelessMarkers.forEach((marker, i) => {
      marker.setMap(null);
    });
    infoWindows.length = 0;
    statelessMarkers.length = 0;
  };

  const renderMarkers = (markers) => {
    let bounds = new GMapsAPI.googleMaps.LatLngBounds();

    if (!markers || markers.length <= 0) {
      return;
    }
    markers.forEach((node, i) => {
      const { coordinates, name } = node;
      const icon = '';

      statelessMarkers[i] = new GMapsAPI.googleMaps.Marker({
        position: coordinates,
        map: map.current,
        title: name,
        icon: icon,
      });
      bounds.extend(coordinates);
      const id = `infoWindowContent${i}`;
      statelessMarkers[i].metadata = { id: `statelessMarkers[i]${i}` };
      infoWindows[i] = new GMapsAPI.googleMaps.InfoWindow({
        content: "<div id='" + id + "'></div>",
      });

      statelessMarkers[i].addListener('click', () => {
        map.current.setCenter(statelessMarkers[i].getPosition());

        this.closeAllInfoBoxes();

        if (!infoWindows[i].getMap()) {
          infoWindows[i].open(map.current, statelessMarkers[i]);
        }

        // render as soon as available
        let myInterval = setInterval(() => {
          if (document.querySelector(`#${id}`)) {
            document.querySelector(`#${id}`).innerHTML = id;

            ReactDOM.render(<MapPopupContent {...node} />, document.querySelector(`#${id}`));
            clearInterval(myInterval);
          }
        }, 50);
      });
    });
    map.current.fitBounds(bounds);
    if (!mapHasCloseListener) {
      map.current.addListener('click', (e) => {
        closeAllInfoBoxes();
      });
      map.current.addListener('zoom_changed', (e) => {
        const zoom = map.current.getZoom();
        const nextZoom = Math.max(furthestZoom, Math.min(closestZoom, zoom));
        if (zoom !== nextZoom) map.current.setZoom(nextZoom);
      });
      mapHasCloseListener = true;
    }
  };

  const closeAllInfoBoxes = () => {
    statelessMarkers.forEach((mrkr, j) => {
      closeInfoBox(j);
    });
  };

  const closeInfoBox = (index) => {
    const unmountMe = document.querySelector('#infoWindowContent' + index);
    if (unmountMe) {
      ReactDOM.unmountComponentAtNode(unmountMe);
      infoWindows[index].close();
    }
  };

  useEffect(() => {
    const onGMAPSAPILOADED = setInterval(() => {
      if (GMapsAPI.googleMaps) {
        map.current = new GMapsAPI.googleMaps.Map(mapContainer.current, {
          center: center,
          zoom: zoom,
        });
        renderMarkers(list);
        clearInterval(onGMAPSAPILOADED);
      }
    }, 250);

    return () => {
      clearInterval(onGMAPSAPILOADED);
      map.current = null;
    };
  });

  useEffect(() => {
    if (map.current) {
      clearMarkers();
      renderMarkers(list);
    }
  }, [list]);

  return (
    <div ref={mapContainer} className={clsx(styles.LazyGMap2, className)}>
      Loading
    </div>
  );
};

LazyGMap2.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  closestZoom: PropTypes.number,
  furthestZoom: PropTypes.number,
};
LazyGMap2.defaultProps = {
  className: '',
  closestZoom: 16,
  furthestZoom: 5,
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  list: [],
  zoom: 11,
};
