import React, { useEffect, useState } from 'react';
import MapAPI from '../../utils/GmapsAPI';

import { LocationField } from './LocationField';
import { DistanceSelect } from './DistanceSelect';
import { BrandSelect } from './BrandSelect';

import { form, title, row, button } from './SearchForm.module.css';
import clsx from 'clsx';

export const SearchForm = (props) => {
  const { onSearch, brands, disabled } = props;
  const [address, setAddress] = useState('');
  const [distance, setDistance] = useState('0');
  const [brand, setBrand] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    if (address) {
      onSearch(address, brand, distance);
    }
  };

  useEffect(() => {
    const invervalCheckMAPSAPI = setInterval(() => {
      if (MapAPI.googleMaps) {
        clearInterval(invervalCheckMAPSAPI);
        askForLocation();
      }
    }, 500);
  }, []);

  const askForLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          MapAPI.getAddress(pos.coords, setAddress);
        },
        (error) => {
          console.warn(error.code + ' ' + error.message);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              console.log('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              console.log('The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              console.log('An unknown error occurred.');
              break;
          }
        },
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  return (
    <form className={form} onSubmit={submitForm}>
      <h3 className={title}>Vertriebspartnersuche</h3>
      <div className={row}>
        <label htmlFor="locationField">PLZ/Ort</label>
        <LocationField address={address} onChange={setAddress} disabled={disabled} />
      </div>

      {brands && brands.length > 0 && (
        <div className={row}>
          <label htmlFor="brandSelect">Hersteller</label>
          <BrandSelect id="brandSelect" brands={brands} onChange={setBrand} disabled={disabled} />
        </div>
      )}

      <div className={row}>
        <label htmlFor="distanceSelect">im Umkreis von</label>
        <DistanceSelect id="distanceSelect" onChange={setDistance} disabled={disabled} />
      </div>

      <button className={clsx(button, 'has-background')} disabled={disabled}>
        Partner suchen!
      </button>
    </form>
  );
};
