import React from 'react';

export const DistanceSelect = (props) => {
  const { onChange, ...rest } = props;
  return (
    <select onChange={(e) => onChange(e.currentTarget.value)} {...rest}>
      <option value="0">Kein Limit</option>
      <option value="250">250km</option>
      <option value="100">100km</option>
      <option value="50">50km</option>
      <option value="30">30km</option>
      <option value="15">15km</option>
    </select>
  );
};
